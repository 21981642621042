@font-face {
  font-family: 'Roboto';
  src: url('Assets/Fonts/Roboto/Roboto-Regular.ttf');
}
@font-face {
  font-family: 'Roboto Bold';
  src: url('Assets/Fonts/Roboto/Roboto-Bold.ttf');
}
@font-face {
  font-family: 'Roboto Light';
  src: url('Assets/Fonts/Roboto/Roboto-Light.ttf');
}


body {
  overflow-y: scroll;
}

body,
html,
#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000e0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Overwrite bootstrap modal title to always red */
.modal-title {
  color: #2961a8;
}

.btn-success {
  background-color: #2961a8 !important;
}

.btn-outline-success {
  border-color: #2961a8 !important;
  color: #2961a8 !important;
}

.btn-outline-success:hover {
  border-color: #2961a8 !important;
  background-color: #2961a8 !important;
  color: #ffffff !important;
}
